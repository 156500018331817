/*
 * CONTENS default implementation of cRowtype.richtext
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   ckeditor
 */
require("./jquery.cms.rowtype");

(function($, window, document, _, HashMap) {

	var sCurrCKEditLang = "de",
		widgetStyleClass = "con-richtext",
		widgetSelectorClass = "js-richtext",
		subobjectsEventClass = widgetSelectorClass + "-subobject",
		infoStyle = widgetStyleClass + "-info",
		infoClass = widgetSelectorClass + "-info",
		detailHeaderObjectId = "js-richtext-link-detail-object-id",
		detailHeaderClassname = "js-richtext-link-detail-classname",
		detailHeaderDetailPages = "js-richtext-instance-detail-pages",
		detailInstanceClass = "js-page-detail-instance",
		nodetailsClass = "js-richtext-internal-instance-no-details",
		detailsClass = "js-richtext-internal-instance-detail",
		locationDetailWrapper = "js-richtext-internal-location-wrapper",
		iconClosedClass = "closed",
		iconOpenClass = "open",
		subpageEventClass = "js-richtext-object-subpage",
		objectPreviewEventClass = "js-richtext-object-preview",
		generateLink,
		oRichtextTemplates,
		tmpRichtextTemplate = null;

	generateLink = function(data) {
		var completeLink,
			linkAttrs = "",
			anchorPrefix = "#",
			aParams = [],
			key = null,
			value;

		if (data.type === 'wikilink') {
			if (data.anchorname !== '') {
				completeLink = "[" + data.codename + "|" + data.anchorname + " " + data.linktext + "]";
			} else {
				completeLink = "[" + data.codename + " " + data.linktext + "]";
			}

			return completeLink;
		}

		if (data.type === 'file') {
			completeLink = "javascript:contensActPage('openfile',";
			completeLink += parseInt(data.object_id, 10) + ",{";
		} else {
			completeLink = "javascript:contensActPage('openpage',";
			completeLink += parseInt(data.page_id, 10) + ",{";
		}

		switch (data.type) {
			case "internalpage":
				linkAttrs = "linktype:1";
				break;

			case "application":
				linkAttrs = "linktype:1,params:{";
				for (key in data.params) {
					if (data.params.hasOwnProperty(key)) {
						value = data.params[key];
						if (typeof value === "string") {
							value = "'" + value + "'";
						}
						aParams.push(key + ":" + value);
					}
				}
				linkAttrs += aParams.join(",");
				linkAttrs += "}";
				break;

			case "pageanchor":
				linkAttrs = "linktype:1,aname:'" + anchorPrefix + data.anchorname + "'";
				break;

			case "detailpage":
				linkAttrs = "linktype:2,inst:" + parseInt(data.instance_id, 10) + ",obj:" + parseInt(data.object_id, 10) + ",page:" + parseInt(data.subpage_nr, 10);
				break;

			case "instance":
				linkAttrs = "linktype:3,inst:" + parseInt(data.instance_id, 10) + ",aname:'" + anchorPrefix + data.anchorname + "'";
				break;

			case "file":
				linkAttrs = "linktype:5,file:" + parseInt(data.file_id, 10);
				break;

			default:
				linkAttrs = "linktype:0";
		}
		completeLink += linkAttrs + "});";

		return completeLink;
	};

	$.widget("cms.cRowtype_richtext_default", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			aCKEInstances: [],
			aCKEIFrames: [],
			bCreateSubobjects: false,
			validation: {
				required: false,
				lengthmax: null,
				lengthmin: null
			},
			setup: {
				defaultValue: null,
				rows: null,
				selectclasses: null,
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null,
				oncopyjsfunction: null,
				smalllistoptions: null
			},
			i18n: {
				custom: {
					js_internallinks_label: "Internal Link",
					js_objectfilelinks_label: "Internal File Link",
					js_searchfieldtext: "Search",
					js_header_object_id: "Object-ID",
					js_header_classname: "Class name",
					js_header_file_id: "File ID",
					js_header_file_name: "File name",
					js_label_detailpages: "Detail pages",
					js_no_objectsfound: "No objects found.",
					js_defaultpagelink: "Page link",
					js_menu_site: "Sites",
					js_menu_lang: "Languages",
					js_objectpreview: "Object preview",
					js_currentlength: "%remaining% / %max%",
					js_no_files_found: "Object has no associated files.",
					js_pagecodename: "Page code name",
					js_linktext: "Link text",
					js_anchorname: "Link anchor name"
				},
				validator: {
					viollengthmin: "",
					viollengthmax: ""
				}
			},
			controllerEvents: {
				getObjectsDetails: null
			},
			previewFn: 'object.preview'
		},

		editorInfo: {}, // number of remaining chars display

		// Private property
		treeNavigation: null,

		_create: function() {
			var dialogLabel = this.options.i18n.custom.js_internallinks_label,
				objectFileDialogLabel = this.options.i18n.custom.js_objectfilelinks_label,
				dialogCustomTxt = {
					headerObjectId: this.options.i18n.custom.js_header_object_id,
					headerClassname: this.options.i18n.custom.js_header_classname,
					labelDetailPages: this.options.i18n.custom.js_label_detailpages,
					noObjectsFound: this.options.i18n.custom.js_no_objectsfound
				},
				self = this;

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			this.initializedLangs = new HashMap();

			// register "object library"
			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.buttonSelector = '.' + subobjectsEventClass;
				this.options.setup.smalllistoptions.ignoreSubobjectCheck = true;
				this.options.setup.smalllistoptions.filter = {
					class_id: this.options.setup.smalllistoptions.filter.class_id
				};
			}

			this._plugin($.cms.extensions.smalllist);

			// modify dialogs
			window.CKEDITOR.on('dialogDefinition', function(ev) {
				// take the dialog name and its definition from the event data

				var dialogName = ev.data.name,
					dialogDefinition = ev.data.definition,
					dialog = dialogDefinition.dialog,
					ckeId = ev.editor.id,
					instanceName = ev.editor.name,
					internalLinkId = 'internallink' + ckeId,
					internalLinkContainerId = 'intlinkcontainer' + ckeId,
					fileLinkId = 'externalObjectFile' + ckeId,
					iframeURL = ev.editor.plugins.link.path + 'contens_link.cfm',
					initDialogTemplate = {
						'ckeditor_id': ckeId,
						'siteMenu': {
							title: ''
						},
						'langMenu': {
							title: ''
						}
					},
					dialogTemplate = $.tmpl('richtext-link-treebase', initDialogTemplate),
					dialogHtml = dialogTemplate.html(),
					initObjectFileSearchTemplate = {
						"ckeditor_id": ckeId
					},
					objectFileSearchTemplate,
					objectFileSearchHtml,
					objectFileResultsTemplate,
					searchButtonId = "objFileSearchBtn_" + ckeId,
					searchInputId = "objFileSearchInp_" + ckeId,
					resultsWrapperID = "extObjWrapper" + ckeId,
					oTreeOptions,
					shwDebounce;

				function _showPage() {
					var urlField = dialogDefinition.dialog.getContentElement('info', 'url'),
						urlFieldVal = urlField.getValue(),
						linkTextVal = dialogDefinition.dialog.getContentElement('info', 'linkDisplayText').getValue(),
						linkData;

					// internal link or internal file link?
					if (urlFieldVal.length) {
						// open internal links tab?
						if (urlFieldVal.indexOf("'openpage'") > 0) {
							dialogDefinition.dialog.selectPage(internalLinkId);
							// open file links tab?
						} else if (urlFieldVal.indexOf("'openfile'") > 0) {
							dialogDefinition.dialog.selectPage(fileLinkId);
						}
						// wiki link?
					} else if (_isWikiLink(linkTextVal)) {
						linkData = _parseWikiLink(linkTextVal);

						if (!jQuery.isEmptyObject(linkData)) {
							urlField.setValue(linkTextVal);
							dialogDefinition.dialog.getContentElement('wiki' + internalLinkId, 'wiki' + internalLinkId + 'codename').setValue(linkData.codename);
							dialogDefinition.dialog.getContentElement('wiki' + internalLinkId, 'wiki' + internalLinkId + 'linktext').setValue(linkData.linktext);
							dialogDefinition.dialog.getContentElement('wiki' + internalLinkId, 'wiki' + internalLinkId + 'anchorname').setValue(linkData.anchorname);

							dialogDefinition.dialog.selectPage('wiki' + internalLinkId);
						}
					}
				}

				function _resizeTab(_tabname, _widths) {
					var tabname = _tabname || internalLinkId,
						widths = _widths || ['40%', '60%'],
						elems = $(dialogDefinition.dialog.getContentElement(tabname, 'hlayout').getElement().$).find('td'),
						i;
					for (i = 0; i < widths.length; i++) {
						$(elems[i]).width(widths[i]);
						$(elems[i]).css('padding', '0');
					}
					dialogDefinition.dialog.getContentElement(tabname, 'hlayout').widths = widths;
				}

				function _isWikiLink(linkText) {
					if (ev.editor.config.wikilinks && parseInt(ev.editor.config.urlParams.class_ID, 10) > 0 && linkText.indexOf('[') === 0 && linkText.charAt(linkText.length - 1) === ']') {
						return true;
					}
					return false;
				}

				function _parseWikiLink(linkText) {
					var linkData = {},
						aSplit;
					linkText = linkText.slice(1, -1);
					aSplit = linkText.split(' ');
					if (aSplit.length >= 2) {
						linkData.codename = aSplit[0];
						aSplit.shift();
						linkData.linktext = aSplit.join(' ');
						linkData.anchorname = '';

						if (linkData.codename.indexOf('|') >= 0) {
							aSplit = linkData.codename.split('|');
							linkData.codename = aSplit[0];
							linkData.anchorname = aSplit[1];
						}
					}
					return linkData;
				}

				function _updateWikiUrl(dialog) {
					var urlField = dialog.getContentElement('info', 'url'),
						linkData = {
							type: 'wikilink',
							codename: dialog.getValueOf('wiki' + internalLinkId, 'wiki' + internalLinkId + 'codename'),
							linktext: dialog.getValueOf('wiki' + internalLinkId, 'wiki' + internalLinkId + 'linktext'),
							anchorname: dialog.getValueOf('wiki' + internalLinkId, 'wiki' + internalLinkId + 'anchorname')
						};

					if (linkData.codename !== '' && linkData.linktext !== '') {
						urlField.setValue(generateLink(linkData));
					}
				}

				initObjectFileSearchTemplate.i18n = self.options.i18n;

				if (dialogName === 'link') {
					self.element.trigger("changerow.rowtype");

					// remove tabs from the "link" dialog: upload, advanced
					dialogDefinition.removeContents('upload');
					if (!ev.editor.config.linkAdvanced) {
						dialogDefinition.removeContents('advanced');
					}

					// override isChanged to block confirm dialog
					dialogDefinition.getContents('info').get('url').isChanged = function() {
						return false;
					};

					// add "internal link" tab only in CONTENS dataclasses
					if (parseInt(ev.editor.config.urlParams.class_ID, 10) > 0) {
						// dialog config & content is not destroyed!
						dialogDefinition.removeContents(internalLinkId);

						dialog.on('show', function() {
							if (shwDebounce) {
								clearTimeout(shwDebounce);
								shwDebounce = undefined;
							}
							shwDebounce = setTimeout(function() {
								_showPage();
								shwDebounce = undefined;
							}, 100);
						});

						dialogDefinition.addContents({
							id: internalLinkId,
							label: dialogLabel,
							elements: [{
								type: 'hbox',
								widths: ['100%', '0%'],
								id: 'hlayout',
								children: [{
										type: 'vbox',
										children: [
											//	page tree
											{
												type: 'html',
												id: internalLinkContainerId,
												html: dialogHtml,
												setup: function(data) {
													var myself = this,
														pageTreeEditorId = '#pagetree_' + ckeId,
														selectpageId = 'selectpage_' + ckeId,
														siteID = window.cms.cBaseApp.getSiteID(),
														langID = parseInt($(ev.editor.element.$).closest('.ui-form-row-language').attr('rel'), 10),
														pageID,
														siteSelectorId = 'sites_' + ckeId,
														languageSelectorId = 'languages_' + ckeId,
														searchSelectorId = 'search_' + ckeId,
														aParseDetails,
														iParsedDetailsLen,
														i;

													// extract loaded url
													dialogDefinition.contensUrl = '';
													if (data.url && data.url.url && data.url.url.indexOf(':contensActPage') > 0 && data.url.url.indexOf('openpage') > 0) {
														dialogDefinition.contensUrl = data.url.url;
														aParseDetails = data.url.url.split(',');
														if (aParseDetails.length > 1) {
															for (i = 0, iParsedDetailsLen = aParseDetails.length; i < iParsedDetailsLen; i += 1) {
																if (!isNaN(aParseDetails[i])) { // grab first numeric value
																	pageID = parseInt(aParseDetails[i], 10);
																	break;
																}
															}

															$.contensAPI(
																'page.getPageSiteLang', {
																	'pageid': pageID
																},
																$.proxy(
																	function(result, success) {
																		if (success) {
																			langID = result.lang_id;
																			siteID = result.site_id;
																		}
																	}, this), [], {
																	async: false
																});
														}
													}

													$('#' + siteSelectorId).cDropdown({
														type: 'navi'
													});
													$('#' + languageSelectorId).cDropdown({
														type: 'navi'
													});

													this.treeNavigation = $(pageTreeEditorId);
													if (langID === 0) {
														langID = self.language || window.cms.cBaseApp.getLangID();
													}
													// destroy old tree, need new tree setup
													if (this.treeNavigation.data('cms-cNavigation') !== undefined) {
														this.treeNavigation.cNavigation('destroy');
													}

													oTreeOptions = {
														'id': selectpageId,
														'site_id': siteID,
														'lang_id': langID,
														'page_id': pageID,
														'height': '380px',
														'siteSelectorId': siteSelectorId,
														'languageSelectorId': languageSelectorId,
														'siteslanguagesdatafn': "tree.getNavSitesLanguages",
														'siteslanguagesargs': {
															site_id: 0,
															lang_id: 0,
															browtype: true
														},
														'navigation': false,
														'apiendpoint': "tree.get",
														'datafn': function(n) {
															var oMeta = n.data,
																iFolderID = (oMeta ? oMeta.folder_id : 0),
																iPageID = (oMeta ? oMeta.page_id : 0),
																resultData = {
																	"operation": (iFolderID ? "getchildren" : "getnewtree"),
																	"pageID": iPageID,
																	"siteID": this.options.site_id,
																	"langID": this.options.lang_id,
																	"initiallyselected": iPageID,
																	"_lowercase": true,
																	"_returnformat": "json_native"
																};

															return resultData;
														}
													};
													/**
													 *loop through all the instances of the navigation tree to see if we have a
													 tree with the data we need before going to the server
													 **/
													$(':cms-cNavigation').each(function() {
														var Navitree = $(this).cNavigation('instance'),
															oData;
														if (parseInt(oTreeOptions.site_id, 10) === parseInt(Navitree.options.site_id, 10) &&
															parseInt(oTreeOptions.lang_id, 10) === parseInt(Navitree.options.lang_id, 10)) {
															oData = Navitree.getData();
															if (!$.isEmptyObject(oData)) {
																oTreeOptions.json_api = {
																	ajax: true,
																	data: oData
																};
															}
															return false;
														}
													});

													// also use the siteslanguages data from the navigation tree
													oTreeOptions.siteslanguagesdata = window.cms.cBaseApp.getSitesLanguages();

													this.treeNavigation.on('selectNode.cNavigation', function(event, data, orgevent) {
														var treePageId = data.data.page_id,
															iframeContent,
															linkData,
															urlField = dialogDefinition.dialog.getContentElement('info', 'url');

														if ($.isEmptyObject(orgevent)) {
															/* only continue if the select node event was not programmatically fired */
															return;
														}

														// update pagecodename if WikiLinks are active
														if (ev.editor.config.wikilinks && parseInt(ev.editor.config.urlParams.class_ID, 10) > 0 && data.data.pagecodename !== '') {
															dialogDefinition.dialog.getContentElement('wiki' + internalLinkId, 'wiki' + internalLinkId + 'codename').setValue(data.data.pagecodename);
														}

														if ($(self.options.aCKEIFrames[instanceName]).is(':visible')) {
															iframeContent = $(self.options.aCKEIFrames[instanceName]).contents();
															$('#page_id', iframeContent).val(treePageId);
															$('form#pagedetailform', iframeContent).submit();

															_resizeTab();

															linkData = {
																type: 'internalpage',
																page_id: parseInt(treePageId, 10)
															};

															urlField.setValue(generateLink(linkData));
															// set url linkType
															dialogDefinition.dialog.setValueOf('info', 'linkType', 'url');
														}
													});

													// initial loading
													this.treeNavigation.on('loadedTree.cNavigation', $.proxy(function() {
														_showPage();

														this.treeNavigation.find('ul.jstree-container-ul').css({
															'display': 'block'
														});
													}, this));

													$('#' + searchSelectorId).on('click', function(e) {
														var callingElement = $(e.currentTarget),
															oOpenArgs = {
																allowMultiselection: false,
																filters: 'tbftsearch',
																tbftsearch: '',
																preselected: ''
															},
															oOpenOptions = {
																controller: 'pages',
																caller: callingElement,
																id: 'pageSearch_smallList',
																title: 'Search',
																size: window.cms.oSettings.javascript.smallList.size,
																filter: {},
																isResizable: true,
																isMaximizable: window.cms.oSettings.javascript.smallList.isMaximizable,
																zIndexAddOverride: parseInt($("table.cke_dialog").css("z-index"), 10) + 10,
																modal: true,
																buttons: {
																	abort: {
																		title: window.cms.i18n.system.text.cancel,
																		type: 'cancel',
																		position: 'se',
																		event: 'close'
																	},
																	apply: {
																		title: window.cms.i18n.system.text.apply,
																		position: 'se',
																		type: 'save',
																		event: 'smalllist_return.cPageSearch',
																		eventData: {
																			type: 'apply'
																		},
																		caller: callingElement
																	}
																}
															};

														// load search dialog
														callingElement.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

														// listen for event from search dialog
														callingElement.on('smalllist_return.cPageSearch',
															function(event, buttonEventArgs, win) {
																var aIds = [],
																	selectedPage,
																	pageResult,
																	oListEl,
																	oWinComponents,
																	pageTree = $('#pagetree_' + ckeId),
																	iSiteID = pageTree.cNavigation('getSite'),
																	iLangID = pageTree.cNavigation('getLanguage');

																if (win.data('cms-cWindow2')) {
																	oWinComponents = win.cWindow2('getComponents', 'cList');
																	if (oWinComponents !== null && oWinComponents.cList.length) {
																		oListEl = $(oWinComponents['cList'][0]);
																		aIds = oListEl.cList('getSelectedElements');
																	}

																	if (aIds.length) {
																		selectedPage = aIds[0];
																		if (oListEl) {
																			oListEl.trigger('close.window');
																		}

																		$.contensAPI('page.get', {
																			pageid: selectedPage.id,
																			withrights: false,
																			withtasks: false,
																			withpublishstatus: false,
																			withreltables: false,
																			actions: 'pages menu',
																			_lowercase: true
																		}, $.proxy(function(pageData) {
																			var iSiteIDSearch = pageData.main.site_id,
																				iLangIDSearch = pageData.main.lang_id;

																			pageResult = pageData;

																			pageTree.one('search_complete.cNavigation', function(event, data) {
																				data.data = {
																					page_id: selectedPage.id,
																					pagecodename: pageResult.main.pagecodename
																				};
																				myself.treeNavigation.trigger('selectNode.cNavigation', [data, {
																					dummyEvent: 1
																				}]);
																			});

																			if (iSiteID != iSiteIDSearch || iLangID != iLangIDSearch) {
																				pageTree.cNavigation('setSiteLang', iSiteIDSearch, iLangIDSearch, selectedPage.id);
																			} else {
																				pageTree.cNavigation('search', selectedPage.id);
																			}
																		}, this), [404, 401]);
																	}
																}
															}
														);
													});

													this.treeNavigation.cNavigation(oTreeOptions);
												}
											}
										]
									},

									// page detail
									{
										type: 'iframe',
										frameborder: false,
										src: iframeURL,
										width: "100%",
										height: 427,
										selectInstance: function(event, currentTarget, instanceElem, iframeContent) {
											var data = instanceElem.data(),
												selectedInstanceId = data.instanceId,
												selectedObjectId = data.objectId,
												selectedAnchorName = "i" + selectedInstanceId,
												selectedPageElem = iframeContent.find('input#page_id'),
												selectedPageId = parseInt(selectedPageElem.val(), 10),
												urlField = dialogDefinition.dialog.getContentElement('info', 'url'),
												linkData = {
													type: 'instance',
													page_id: selectedPageId,
													instance_id: selectedInstanceId,
													object_id: selectedObjectId,
													anchorname: selectedAnchorName
												},
												valueStr;

											iframeContent.find('.con-button-save').removeClass('con-button-save');

											event.preventDefault();
											event.stopPropagation();
											event.currentTarget.focus();

											$(event.currentTarget).closest('form').find(".linkInstanceSelected").removeClass("linkInstanceSelected");
											$(event.currentTarget).closest(".con-richtext-internal-location-row").addClass("linkInstanceSelected");

											$(event.currentTarget).closest('form').find(".con-button-save").removeClass("con-button-save");
											$(event.currentTarget).closest(".con-button").addClass('con-button-save');

											// subpage selected
											if (currentTarget.hasClass(subpageEventClass)) {
												linkData.type = "detailpage";
												linkData.subpage_nr = currentTarget.data("subpage-nr");
												currentTarget.addClass('con-button-save');
											}

											valueStr = generateLink(linkData);
											urlField.setValue(valueStr);
											// set to url in dropdown
											dialogDefinition.dialog.setValueOf('info', 'linkType', 'url');
										},
										onContentLoad: function() {
											var iframe = document.getElementById(this._.frameId),
												oIframe = $(iframe),
												iframeContent = oIframe.contents(),
												elPageIdInput = iframeContent.find('#page_id'),
												regexMatches,
												instRegex = /inst\:\s*(\d*)/i,
												pageRegex = /page\:\s*(\d*)/i,
												fileRegex = /'openfile',\s*(\d*)/i,
												urlFieldText;

											// update external reference ()
											self.options.aCKEIFrames[instanceName] = "iframe#" + this._.frameId;

											if (elPageIdInput.val() === "0") {
												var pageTreeEditorId = '#pagetree_' + ckeId,
													otreePageData = $(pageTreeEditorId).cNavigation('option', 'page_id');
												if (otreePageData) {
													elPageIdInput.val(otreePageData);

													$('form#pagedetailform', iframeContent).submit();
													_resizeTab();
													return;
												} else {
													_resizeTab(null, ['100%', '0']);
												}
											}

											// set default page link text
											iframeContent.find('.' + detailHeaderObjectId).html(dialogCustomTxt.headerObjectId);
											iframeContent.find('.' + detailHeaderClassname).html(dialogCustomTxt.headerClassname);
											iframeContent.find('.' + detailHeaderDetailPages).html(dialogCustomTxt.labelDetailPages + ':');
											iframeContent.find('.' + nodetailsClass).html(dialogCustomTxt.noObjectsFound);

											iframeContent.on('click', '.js-page-detail-default', function(event) {
												var urlField = dialogDefinition.dialog.getContentElement('info', 'url'),
													selectedPageElem = iframeContent.find('#page_id'),
													selectedPageId = parseInt(selectedPageElem.val(), 10),
													linkData = {
														type: 'internalpage',
														page_id: selectedPageId
													},
													valueStr = generateLink(linkData);

												event.preventDefault();

												urlField.setValue(valueStr);
											});

											iframeContent.on('click', '.js-richtext-internal-detail-wrapper', function(event) {
												var currentTarget = $(event.currentTarget),
													locationWrapper = currentTarget.closest('.' + locationDetailWrapper),
													locationTable = $('.con-richtext-internal-locations', locationWrapper);

												event.preventDefault();

												if (locationTable) {
													if (locationTable.is(':visible')) {
														locationWrapper.removeClass(iconClosedClass).addClass(iconOpenClass);
														locationTable.fadeOut(500);
													} else {
														locationWrapper.removeClass(iconOpenClass).addClass(iconClosedClass);
														locationTable.fadeIn(500);
													}
												}
											});

											iframeContent.on('click', '.' + detailsClass, function(event) {
												var currentTarget = $(event.currentTarget);
												this.selectInstance(event, currentTarget, currentTarget, iframeContent);
											}.bind(this));

											iframeContent.on('click', '.' + detailInstanceClass + " .con-button", function(event) {
												var currentTarget = $(event.currentTarget),
													instanceElem = currentTarget.closest('.' + detailsClass);
												this.selectInstance(event, currentTarget, instanceElem, iframeContent);
											}.bind(this));

											urlFieldText = dialogDefinition.dialog.getContentElement('info', 'url').getValue();

											if (urlFieldText && urlFieldText.indexOf(':contensActPage') > 0) {
												regexMatches = urlFieldText.match(instRegex);
												if (regexMatches) {
													var highlightEl = iframeContent.find('.con-richtext-internal-location-row[data-instance-id="' + regexMatches[1] + '"]');
													if (highlightEl) {
														var regexPageMatches = urlFieldText.match(pageRegex);
														if (regexPageMatches) {
															highlightEl.find('.js-page-detail-instance div[data-subpage-nr="' + regexPageMatches[1] + '"]').addClass('con-button-save');
														} else {
															highlightEl.find('.js-page-detail-instance > .con-button').addClass('con-button-save');
														}
														highlightEl.addClass("linkInstanceSelected");
														highlightEl.closest('form').scrollTo(highlightEl);
													}
												} else {
													regexMatches = urlFieldText.match(fileRegex);
													if (regexMatches) {
														$("#" + searchButtonId).trigger('smalllist_return.cObjectFileSearch', [
															[{
																'id': regexMatches[1]
															}]
														]);
													}
												}
											}

											iframeContent.on('click', '.' + objectPreviewEventClass, $.proxy(function(event) {
												event.preventDefault();
												event.stopPropagation();
												self._handleObjectPreview(event);
											}, this));
										}
									}
								]
							}]
						});
					}

					// add "file link" tab only in CONTENS dataclasses
					if (ev.editor.config.filelinks && parseInt(ev.editor.config.urlParams.class_ID, 10) > 0) {
						// Generate HTML only when it's needed.
						objectFileSearchTemplate = $.tmpl('richtext-objectFile-search', initObjectFileSearchTemplate);
						objectFileSearchHtml = $(objectFileSearchTemplate).html();
						objectFileResultsTemplate = $.tmpl('richtext-objectFile-results', initObjectFileSearchTemplate);
						objectFileResultsTemplate = objectFileResultsTemplate.html();

						// dialog config & content is not destroyed!
						dialogDefinition.removeContents(fileLinkId);

						// Clear link tab
						dialogDefinition.dialog.on("show", function() {
							$("#" + searchInputId).val("");
							$('#' + resultsWrapperID + " > div").first().html('');
							$('#' + resultsWrapperID + " .js-objectfile-resultList").html('');
						});

						// file links tab
						dialogDefinition.addContents({
							id: fileLinkId,
							label: objectFileDialogLabel,
							elements: [
								// search Text
								{
									type: 'html',
									id: 'searchText',
									label: self.options.i18n.custom.js_searchfieldtext,
									'html': objectFileSearchHtml,
									setup: function() {
										// listen for event from search dialog
										$("#" + searchButtonId).on('smalllist_return.cObjectFileSearch',
											function(event, buttonEventArgs, win) {
												var aIds = [],
													selectedObject,
													oListEl,
													oWinComponents,
													sLabel = '';

												if (win && win.data('cms-cWindow2')) {
													oWinComponents = win.cWindow2('getComponents', 'cList');
													if (oWinComponents !== null && oWinComponents.cList.length) {
														oListEl = $(oWinComponents['cList'][0]);
														aIds = oListEl.cList('getSelectedElements');
													}
												} else {
													aIds = buttonEventArgs;
												}

												if (aIds.length) {
													selectedObject = aIds[0];
													if (oListEl) {
														oListEl.trigger('close.window');
													}

													if (selectedObject.label) {
														sLabel = $("<div>" + selectedObject.label + "</div>").text();
														if (sLabel.length > 82) {
															sLabel = sLabel.substr(0, 80) + "...";
														}
														sLabel = ', ' + self.options.i18n.custom.js_header_classname + ': ' + sLabel;
													}

													$('#' + resultsWrapperID + " > div").first().html(self.options.i18n.custom.js_header_object_id + ': ' + selectedObject.id + sLabel + '<br>');

													// check for files in object content
													$.contensAPI(
														'object.getObjectFiles', {
															'objectId': selectedObject.id,
															'langId': self.language || window.cms.cBaseApp.getLangID()
														},
														$.proxy(
															function(result) {
																var linkData, valueStr, listhtml, listel, i, listWrap = $('#' + resultsWrapperID + " .js-objectfile-resultList"),
																	urlField = dialogDefinition.dialog.getContentElement('info', 'url');
																listhtml = "";
																if (result.data.file_id.length > 0) {
																	$('#extObjFileList' + ckeId).show();
																	$('#extObjFileNotFound' + ckeId).hide();
																} else {
																	$('#extObjFileList' + ckeId).hide();
																	$('#extObjFileNotFound' + ckeId).show();
																}
																for (i = 0; i < result.data.file_id.length; i++) {
																	listel = '<tr>' + '<td class="js-richtext-instance-detail-objectFile-id js-page-detail-instance">' + '<div class="con-button">' + '<div class="con-button-label">' + result.data.file_id[i] + '</span>' + '</div>' + '</td>' + '<td class="js-page-detail-objectFile-name">' + '<div>' + result.data.filename[i] + '</div>' + '</td>' + '</tr>';
																	listhtml = listhtml + listel;
																}
																listWrap.html(listhtml);
																listWrap.find('.con-button-label').click(function() {
																	listWrap.find('.con-button-save').removeClass("con-button-save");
																	$(this).parent().addClass("con-button-save");
																	linkData = {
																		type: 'file',
																		object_id: selectedObject.id,
																		file_id: $(this).text()
																	};
																	valueStr = generateLink(linkData);
																	urlField.setValue(valueStr);
																});

																if (result.data.file_id.length === 1) {
																	listWrap.find('.con-button-label').click();
																}
															}, this), [], {
															async: false
														}
													);
												}
											}
										);

										function loadSearchDialog(callingElement) {
											var smallListZindex,
												oOpenArgs = {
													allowMultiselection: false,
													filters: "tbftsearch,lang_id,isfilehandling",
													tbftsearch: $("#" + searchInputId).val(),
													lang_id: self.language || window.cms.cBaseApp.getLangID(),
													isfilehandling: 1,
													preselected: ''
												},
												oOpenOptions = {
													controller: 'objects',
													caller: callingElement,
													id: 'objectFileSearch_' + ckeId + '-' + 'smallList',
													title: 'Search',
													size: window.cms.oSettings.javascript.smallObjectLibrary.size,
													filter: {},
													isResizable: true,
													isMaximizable: window.cms.oSettings.javascript.smallObjectLibrary.isMaximizable,
													modal: true,
													buttons: {
														abort: {
															title: window.cms.i18n.system.text.cancel,
															type: 'cancel',
															position: 'se',
															event: 'close'
														},
														apply: {
															title: window.cms.i18n.system.text.apply,
															position: 'se',
															type: 'save',
															event: 'smalllist_return.cObjectFileSearch',
															eventData: {
																type: 'apply'
															},
															caller: callingElement
														}
													}
												};

											// load search dialog
											$("#" + searchButtonId).trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

											// make sure that the CKE Dialog is behind the search dialog
											smallListZindex = $('#objectFileSearch_' + ckeId + '-' + 'smallList').css("z-index");
											$('.' + ckeId + ' .cke_dialog').parent().css("z-index", smallListZindex - 5);
											$('.cke_dialog_background_cover').css("z-index", smallListZindex - 6);
										}

										$('#' + searchInputId).keydown(function(evt) {
											if (evt.keyCode == 13) {
												evt.preventDefault();
												evt.stopImmediatePropagation();
												evt.stopPropagation();
												loadSearchDialog($("#" + searchButtonId)[0]);
											}
										});

										$("#" + searchButtonId).click(function() {
											loadSearchDialog($("#" + searchButtonId)[0]);
										});
									}
								},
								// search results
								{
									type: 'html',
									id: 'searchResults',
									'html': objectFileResultsTemplate,
									'setup': function() {
										$.noop();
									}
								}
							]
						});
					}

					// add "wiki link" tab only in CONTENS dataclasses
					if (ev.editor.config.wikilinks && parseInt(ev.editor.config.urlParams.class_ID, 10) > 0) {
						// dialog config & content is not destroyed!
						dialogDefinition.removeContents('wiki' + internalLinkId);

						// set link to WikiLink if we select the tab
						dialogDefinition.dialog.on('selectPage', function() {
							var curTab = dialogDefinition.dialog._.currentTabId;
							if (curTab && curTab.indexOf('wiki') === 0) {
								_updateWikiUrl(dialogDefinition.dialog);
							}
						});

						// wiki links tab
						dialogDefinition.addContents({
							id: 'wiki' + internalLinkId,
							label: 'Wiki Link',
							elements: [{
									type: 'text',
									label: self.options.i18n.custom.js_pagecodename,
									id: 'wiki' + internalLinkId + 'codename',
									onChange: function() {
										_updateWikiUrl(this.getDialog());
									}
								},
								{
									type: 'text',
									label: self.options.i18n.custom.js_linktext,
									id: 'wiki' + internalLinkId + 'linktext',
									onChange: function() {
										_updateWikiUrl(this.getDialog());
									}
								},
								{
									type: 'text',
									label: self.options.i18n.custom.js_anchorname,
									id: 'wiki' + internalLinkId + 'anchorname',
									onChange: function() {
										_updateWikiUrl(this.getDialog());
									}
								}
							]
						});
					}
				}
			});
		},

		_init: function() {
			this.element.on("multiusage.addRow", $.proxy(this._handleAddRichtextRow, this));
			this.options.modificators.onStopSortOrder = this._handleOnStopSortOrder;

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			this.destroyEditors();
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_getPasteItemIdFromClipboard: function() {
			// get first object_id from clipboard that that has an allowed class_id.
			var classes = this.options.setup.classes;
			var clipitem, clipboard = window.cms.cWorkspace.getClipboard('object');
			if (!clipboard || !classes || !classes.length) {
				return false;
			}
			var i, j, ret = false;
			for (i = 0; i < clipboard.length && !ret; i++) {
				clipitem = clipboard.getByIndex(i);
				for (j = 0; j < classes.length && !ret; j++) {
					if (clipitem.class_id == classes[j].class_id) {
						ret = clipitem.object_id;
					}
				}
			}
			return ret;
		},
		_handleClipboardPaste: function(isCopy) {
			var objectid, copyok, self = this;

			objectid = this._getPasteItemIdFromClipboard();

			if (isCopy) {
				copyok = false;
				$.contensAPI(
					'object.copy', {
						'objectId': objectid
					},
					function(result, success) {
						if (success) {
							objectid = result.object_id;
							copyok = true;
						}
					}, [], {
						async: false
					});
				if (!copyok) {
					return;
				}
			}

			self._addObjectSnippet({
				obj_ids: objectid,
				lang_ids: self.language,
				source_elem: self.ckeid
			});
		},

		/* custom functions */
		createEditor: function(CKEid, jEl, idx, ilang) {
			/* gui language */
			var currGuiLangID = (window.cms.cBaseApp.getGuilangID() || 2),
				editor_config = {},
				sUrlVariables = "",
				classId = 0,
				formName = "",
				bAdminForm = true,
				iEditorHeight = 150,
				oCKEditorInstance,
				self = this,
				timeoutId = 0,
				sCustomConfig,
				kbEvtForward;

			self.ckeid = CKEid;

			switch (currGuiLangID) {
				case 1:
					sCurrCKEditLang = "en";
					break;
				case 2:
					sCurrCKEditLang = "de";
					break;
				case 3:
					sCurrCKEditLang = "fr";
					break;
			}

			if (this.options.form.cForm('option', 'isAdminForm') === 0) {
				classId = this.options.form.cForm('option', 'class_id');
				bAdminForm = false;
			}
			formName = this.options.form.cForm('option', 'name');

			if (this.options.setup.rows && !isNaN(this.options.setup.rows)) {
				iEditorHeight = this.options.setup.rows * 30;
			}

			sUrlVariables = '?class_ID=' + classId + '&formName=' + formName + '&bAddSubObjects=' + (this.options.setup.selectclasses !== null && this.options.setup.selectclasses.length > 0) + '&bAdminForm=' + bAdminForm + '&iEditorHeight=' + iEditorHeight + '&ckeid=' + CKEid;

			kbEvtForward = function kbEvtForward(kbEvt) {
				kbEvt.cancel();
				var evt = jQuery.Event("keydown");
				$.extend(evt, kbEvt.data.domEvent.$);

				self.element.trigger(evt);
			};

			if (this.form.cForm('getLanguageDir', ilang)) {
				editor_config.contentsLangDirection = 'rtl';
			}

			if (ilang) {
				editor_config.contentsLanguage = this.form.cForm('getLanguageIso', ilang);
			}

			editor_config.form = this.form;

			editor_config.on = {
				key: function(event) {
					// ctrl+s (save) / ctrl+shift+s (=save an close)
					if (event.data.keyCode === (CKEDITOR.CTRL + 83) || event.data.keyCode === (CKEDITOR.CTRL + CKEDITOR.SHIFT + 83)) {
						kbEvtForward(event);
					}
					// esc (=close)
					else if (event.data.keyCode === 27) {
						kbEvtForward(event);
					}
				}
			};

			if ((window.cms.debug.mode & 32) === 32) {
				sCustomConfig = '../config_contens.cfm' + sUrlVariables;
			} else {
				sCustomConfig = './config_contens.cfm' + sUrlVariables;
			}

			$.extend(editor_config, {
				height: iEditorHeight,
				language: sCurrCKEditLang,
				customConfig: sCustomConfig,
				startupFocus: false,
				skin: 'contens'
			}, this.options.setup);

			// correct idx for multiusage elements
			if (idx > 0) {
				idx--;
			}
			// set textarea value, _setValue() is not safe!
			if (this.initValue && this.initValue['main'] && this.initValue['main'][ilang] && this.initValue['main'][ilang][idx]) {
				jEl.val(this.initValue['main'][ilang][idx]);
			}
			// create CKE, use reference instead of direct window.CKEDITOR.instances[CKEid] access
			oCKEditorInstance = window.CKEDITOR.replace(CKEid, editor_config);

			this.options.aCKEInstances.push(CKEid);
			this.options.aCKEIFrames.push(CKEid);

			// add meta data
			oCKEditorInstance.elMeta = {
				"orichtext": self,
				"ckeid": CKEid,
				"formLanguage": this.oForm.options.language,
				jEl: jEl,
				idx: idx,
				ilang: ilang
			};

			if (parseInt(this.options.validation.lengthmax, 10) > 0) {
				oCKEditorInstance.on('dataReady', $.proxy(function() {
					this._updateStatusBar(CKEid);
				}, this));
			}

			oCKEditorInstance.on('instanceReady', $.proxy(function() {
				var subobjectsCommandStr = 'SubObjects',
					instanceEl = $(window.CKEDITOR.instances[CKEid].element.$),
					oSubobjContextData,
					oSubobjButton,
					oObjectWrapperData,
					contextMenuItems;

				if (parseInt(this.options.validation.lengthmax, 10) > 0) {
					var editorInfoId = 'editor-info_' + CKEid;

					$('#' + editorInfoId).remove();
					$.tmpl('richtext-info').insertAfter($('#cke_' + CKEid)).prop("id", editorInfoId);
					this._updateStatusBar(CKEid);
				}

				if (instanceEl.is(':hidden')) {
					// Listen to iframe load event to reinitialize CK editor only if the editor is hidden
					instanceEl.one('load', this._reInitEditor.bind(this, CKEid));
					// Make sure to reinitialize CK editor on form wrap.
					this.form.one('wrap', this._reInitEditor.bind(this, CKEid));
				}
				// Unbind iframe event listeners on form submit to prevent errors.
				this.form.one('submit', function() {
					instanceEl.off('load');
				}.bind(this));

				// override & modify sub-objects command
				if (self.options.bCreateSubobjects) {
					oObjectWrapperData = this.form.closest(".con-window-wrapper-object");
					oSubobjButton = $(oCKEditorInstance.container.$).find('.cke_button__subobjects'); // Somehow changed in new CKE version

					if (((oObjectWrapperData && oObjectWrapperData.length) || window.undocked) && oSubobjButton.length) {
						if (window.undocked) {
							oObjectWrapperData = $.getUrlVars();
						} else {
							oObjectWrapperData = oObjectWrapperData.data('cms-cWindow2').options.data;
						}

						contextMenuItems = [{
							'id': 'minsert',
							'title': '',
							'iconclass': '',
							'isseparator': true,
							'action': {
								'type': 'event',
								'event': 'addsubobjcontextwizard.widget',
								'exec': 'onInit',
								'args': {
									'isEmptyLocation': true,
									i18n: {
										'searchFieldText': self.options.i18n.custom.js_searchfieldtext,
										'after': self.options.i18n.custom.js_after,
										'before': self.options.i18n.custom.js_before,
										'insertwhat': self.options.i18n.custom.js_insertwhat,
										'insertwhere': self.options.i18n.custom.js_insertwhere,
										'insertnew': self.options.i18n.custom.js_insertnew,
										'insertbib': self.options.i18n.custom.js_insertbib,
										'idsearch': window.cms.i18n.workspace.text.idsearch,
										'selectclass': self.options.i18n.custom.js_selectclass
									}
								}
							}
						}];
						if (self._getPasteItemIdFromClipboard()) {
							contextMenuItems.push({
								'id': 'mpaste',
								'title': window.cms.i18n.workspace.text.insert,
								'iconclass': 'insert',
								'action': {
									'type': 'fn',
									"callback": function() {
										self._handleClipboardPaste.apply(self, []);
									}
								}
							});
						}
						if (self._getPasteItemIdFromClipboard() &&
							window.cms.oSettings.javascript.workspacesettings.object_contextmenu.allowduplicates) {
							contextMenuItems.push({
								'id': 'mpastecopy',
								'title': window.cms.i18n.workspace.text.copyclip,
								'iconclass': 'insert',
								'action': {
									'type': 'fn',
									"callback": function() {
										self._handleClipboardPaste.apply(self, [true]);
									}
								}
							});
						}

						// show insert object contextmenu
						oSubobjContextData = {
							'id': 'ckeditor_' + CKEid,
							'menuEvent': 'click contextmenu',
							'menuClass': 'con-context',
							elMeta: {
								"mainobjectdata": oObjectWrapperData,
								"subobjContext": oSubobjButton,
								"classes": self.options.setup.classes,
								"filterclasses": this.options.setup.smalllistoptions.filter.class_id,
								"orichtext": self,
								"ckeid": CKEid,
								"formLanguage": this.oForm.options.language
							},
							'appendTo': window.top.document.body,
							'items': {
								'fn': function() {
									return contextMenuItems;
								}
							}
						};
						oSubobjButton.cSubobjContext(oSubobjContextData);
						oCKEditorInstance.elMeta = oSubobjContextData.elMeta;

						oCKEditorInstance.on('focus', function() {
							oSubobjButton.cSubobjContext('hideContext');
						});

						// subobject class
						this.element.find('iframe').contents().find('[data-object_id]').addClass('cms-subobject-wrapper');

					}
				}

				oCKEditorInstance.oldSubobjectsCommand = oCKEditorInstance.getCommand(subobjectsCommandStr);
				oCKEditorInstance.addCommand(subobjectsCommandStr, {
					exec: function(editor) {
						var buttonId;

						if (editor.oldSubobjectsCommand.uiItems.length > 0 && editor.oldSubobjectsCommand.uiItems[0] && editor.oldSubobjectsCommand.uiItems[0]._.id) {
							buttonId = editor.oldSubobjectsCommand.uiItems[0]._.id;

							if (!self.options.bCreateSubobjects) {
								// register small list event class (smalllist plug-in)
								$('#' + buttonId).addClass(subobjectsEventClass);
							}
						}
					}
				});

				if (this.options.setFocus === true) {
					oCKEditorInstance.focus();
				}
			}, this));

			// change handler
			oCKEditorInstance.on('change', $.proxy(function(ev) {
				this._handleInputUpdate(ev, CKEid);
			}, this));

			// key events works in source and wysiwyg mode
			oCKEditorInstance.on('key', $.proxy(function(ev) {
				if (ev.editor.mode === "source") {
					clearTimeout(timeoutId);
					timeoutId = setTimeout(
						this._handleInputUpdate.bind(this, ev, CKEid),
						50
					);
				}
			}, this));

			// fired when the editor content (its DOM structure) is ready
			oCKEditorInstance.on('contentDom', $.proxy(function() {
				oCKEditorInstance.updateElement();

				oCKEditorInstance.document.on('dragstart', $.proxy(function(ev) {
					this._handleInputUpdate(ev, CKEid);
				}, this));

				oCKEditorInstance.document.on('changeStyle', $.proxy(function(ev) {
					this._handleInputUpdate(ev, CKEid);
				}, this));

				oCKEditorInstance.on('paste', $.proxy(function(ev) {
					this._handleInputUpdate(ev, CKEid);
				}, this));

				oCKEditorInstance.on('afterCommandExec', $.proxy(function(ev) {
					ev.ckeCommand = true;
					// ignore autogrow command, otherwhise validation is executed to fast
					if (!(ev.data && ev.data.name && ev.data.name === 'autogrow')) {
						this._handleInputChange(ev, CKEid);
					}
				}, this));

				oCKEditorInstance.on('blur', $.proxy(function() {
					var thisjEl = $("#" + CKEid);
					$.cms.cRowtype.prototype._handleInputBlur.apply(this, $.Event('blur', {
						currentTarget: thisjEl[0]
					}));
				}, this));

				if (this.options.setup.classes.length) {
					oCKEditorInstance.on('doubleclick', $.proxy(function(event) {
						var eventTarget = event.data.element.$,
							subObjectContainerElement = $(eventTarget).closest('[contenteditable="false"]'),
							oSubObjectData = {};
						if (subObjectContainerElement.length === 1) {
							// Make sure we select the element so it can be replaced
							oCKEditorInstance.getSelection().selectElement(new CKEDITOR.dom.element(subObjectContainerElement[0]));

							oSubObjectData = subObjectContainerElement.data();
							$(document.body).trigger('openRecord.workspace', ['objects', {
								object_id: oSubObjectData.object_id,
								datalang_id: oSubObjectData.lang_id
							}]);

							// update ckeditor content with saved content, delete and re-add
							// As the element is now selected, it doesn't need to be deleted.
							$('#object' + oSubObjectData.object_id).on('saveSuccess.form', $.proxy(function(event, oResult) {
								var iTransLang = oCKEditorInstance.elMeta.orichtext.language;
								try {
									iTransLang = parseInt(oCKEditorInstance.elMeta.ckeid.split('-')[1], 10); // multilangmultilang-3-1_1406302092651 -> 3
								} catch (e) {
									$.noop();
								}

								if (iTransLang === 0) {
									iTransLang = oCKEditorInstance.elMeta.formLanguage;
								}

								oCKEditorInstance.elMeta.orichtext._addObjectSnippet({
									obj_ids: oResult.result.main.object_id,
									lang_ids: iTransLang,
									source_elem: oCKEditorInstance.elMeta.ckeid,
									source_outputtypeid: oSubObjectData.outputtype_id.toString()
								});

							}, oCKEditorInstance));
						}
					}, this));
				}

				oCKEditorInstance.on('change', $.proxy(function(ev) {
					this._handleInputChange(ev, CKEid);
				}, this));

				oCKEditorInstance.on('maximize', $.proxy(function(ev) {
					var wrp = $(ev.editor.container.$).find('.cke_maximized'),
						zind,
						newZind,
						elsToChange;
					zind = wrp.css('z-index');
					// For some reason CKe sets all elements all the way up up to HTML to the same z-index !!
					elsToChange = $('[style*="z-index: ' + zind + '"]');
					elsToChange.css('z-index', 0);
					newZind = $.highestZIndex() + 1;
					wrp.css('z-index', newZind);
				}, this));

			}, this));
		},

		destroyEditors: function() {
			var idx = null;

			for (idx in this.options.aCKEInstances) {
				if (window.CKEDITOR.instances.hasOwnProperty(this.options.aCKEInstances[idx])) {
					var editorInstance = window.CKEDITOR.instances[this.options.aCKEInstances[idx]];

					if (editorInstance.elMeta && editorInstance.elMeta.subobjContext) {
						// remove contextmenu created by the richtext editor
						editorInstance.elMeta.subobjContext.cSubobjContext('destroy');
					}
					if (editorInstance.destroy) {
						editorInstance.destroy();
					}
				}
			}
			this.options.aCKEInstances = [];
			this.options.aCKEIFrames = [];
		},

		_handleInputBlur: function(e) {
			/* by blur we need to use the target not the current target */
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}

			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},

		_handleInputUpdate: function(event, id) {
			var editorElement = $("#" + id);

			if (window.CKEDITOR.instances[id]) {
				this.element.trigger("changerow.rowtype", $.Event('keyup', {
					currentTarget: editorElement[0]
				}));

				window.CKEDITOR.instances[id].updateElement();
				if (parseInt(this.options.validation.lengthmax, 10) > 0) {

					this._updateStatusBar(id);
					if (event.editor.mode === "source") {
						// change events not triggered in source mode need to manually validate
						this.validate();
					}

				}
			}
		},

		_handleAddRichtextRow: function(event, oParams) {
			if (oParams && !oParams.isInternal) {
				this.options.setFocus = true;
			}
		},

		_handleOnStopSortOrder: function(draggedEl) {
			var editorId = draggedEl.find('textarea').attr('id');

			// Best to recreate CKEditor here because during DragNDrop iFrame gets lost.
			this._reInitEditor(editorId);
		},

		/* internal custom functions */
		_initElement: function(jEl, idx, ilang) {
			var CKEid, jElWork;
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			jElWork = jEl.is('input, textarea, select') ? jEl : jEl.find('input, textarea, select');
			CKEid = jElWork.attr('id') + '_' + Number(new Date()); // unique id!
			jElWork.attr('id', CKEid);

			// dataclasses
			if (this.oForm.options.monitorlangchange) {
				this.createEditor(CKEid, jElWork, idx, ilang);
			} else {
				// admin forms
				// only intialize the form language first
				if (ilang === 0 || ilang === this.oForm.options.language) {
					this.initializedLangs.put(ilang, CKEid);
					this.createEditor(CKEid, jElWork, idx, ilang);
				}
			}
		},

		/* Reinitialize existing editor instance. */
		_reInitEditor: function(editorId) {
			var editor = window.CKEDITOR.instances[editorId];

			if (!editor) {
				return;
			}

			var meta = editor.elMeta;
			var data = editor.getData();

			editor.dragDestroy();
			meta.orichtext.createEditor(meta.ckeid, meta.jEl, meta.idx, meta.ilang);
			editor.setData(data);
		},

		/**
		 * Used by _copyTranslation to obtain the javascript type/object that can be passed to _setValue.
		 * To cancel the _copyTranslation operation, return undefined.
		 */
		_getValueObject: function _getValueObject(jEl) {
			var CKEid = jEl.attr('id');

			var txt = window.CKEDITOR.instances[CKEid].getData();
			return txt;
		},
		/**
		 * Parse and validate value for left-right copy before passing it to _setValue.
		 * To cancel operation, return undefined
		 */
		_copyTranslationParseValue: function _copyTranslationParseValue(val, destLangId) {
			var txt = val;
			txt = txt.replace(/\slinkid\=\"[0-9]+\"/gi, ' linkid="0"');
			txt = txt.replace(new RegExp('lang\\-id\\=\\"' + this.language + '\\"', "gi"), 'lang-id="' + destLangId + '"');
			txt = txt.replace(new RegExp('lang\\_id\\=\\"' + this.language + '\\"', "gi"), 'lang_id="' + destLangId + '"');
			return txt;
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_canAutoTranslate: function _canAutoTranslate() {
			return true;
		},

		// important: _setValue is different from all other rowtypes! value is set in createEditor method, before replacing ckeditor textarea
		// this is the safest way to set ckeditor values, because setData is async and has timing issues
		_setValue: function(jEl, value) {
			var CKEid = jEl.attr('id');

			if ((this.options.validation.lengthmax || this.options.validation.lengthmin) && jEl) {
				this._checkLength(jEl, CKEid);
			}

			if (window.CKEDITOR.instances[CKEid]) {
				window.CKEDITOR.instances[CKEid].setData(value);
			} else {
				jEl.val(value);
			}
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this),
				forcefunctionOveride;

			forcefunctionOveride = {
				errorMsg: function() {
					return "";
				},
				test: function(jEl) {
					if (this.options.setup.forcefunction) {
						var id = jEl[0].id,
							oEditor = window.CKEDITOR.instances[id],
							sData = oEditor.getData(),
							sResult = this.options.setup.forcefunction(sData);

						if (oEditor && sData != sResult) {
							jEl.val(sResult);
							_.defer(function() {
								oEditor.setData(sResult);
							});
						}
					}
				}
			};

			// order of functions which modify the value is important!

			if (this.options.validation.forcefunction) {
				this.validators["forcefunction"] = forcefunctionOveride;
			}

			if (this.options.validation.forceremovetags) {
				aValidators.push("forceremovetags");
			}

			if (this.options.validation.forcefunction) {
				aValidators.push('forcefunction{"forcefunction": "' + this.options.validation.forcefunction + '"}');
			}

			if (this.options.validation.required) {
				aValidators.push('required');
			}

			if (this.options.validation.lengthmax) {
				this.validator_add("maxlengthwithhtml",
					function(jEl) {
						var invalidInputDetected = false,
							testValue = $(jEl).val();

						if (this._getLengthWithoutHTML(testValue) > this.options.validation.lengthmax) {
							invalidInputDetected = true;
						}
						return invalidInputDetected;
					},
					function() {
						return this.options.i18n.validator.viollengthmax.replace('%max%', this.options.validation.lengthmax);
					}
				);
				aValidators.push('maxlengthwithhtml');
			}

			if (this.options.validation.lengthmin) {
				this.validator_add("minlengthwithhtml",
					function(jEl) {
						var invalidInputDetected = false,
							testValue = $(jEl).val();

						if (this._getLengthWithoutHTML(testValue) < this.options.validation.lengthmin) {
							invalidInputDetected = true;
						}
						return invalidInputDetected;
					},
					function() {
						return this.options.i18n.validator.viollengthmin.replace('%min%', this.options.validation.lengthmin);
					}
				);
				aValidators.push('minlengthwithhtml');
			}

			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}

			return aValidators;
		},

		_bindInput: function() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},

		_getClearTextInfo: function(text) {
			var regexStripHTML = /<[^<|>]+?>/gi,
				clearTxt = $.trim(text).replace(regexStripHTML, ''),
				clearTxtLength = clearTxt.length,
				result = {
					text: clearTxt,
					length: clearTxtLength
				};

			return result;
		},

		// handle smalllist apply event
		_transferValues: function(data) {
			var iLang = 0,
				iTransLang = 0,
				iObjectID = 0,
				iClassID = 0,
				idx = 0,
				elemCount = 0,
				aObjData = [],
				aLangData = [],
				sourceElement,
				outputtypes = [],
				bAddSnippet = true;

			if (this.options.setup.smalllistoptions !== null) {
				if (this.options.setup.smalllistoptions.openArgs.allowMultiselection) {
					for (iLang in data) {
						if (data.hasOwnProperty(iLang)) {
							elemCount = data[iLang].length;
							for (idx = 0; idx < elemCount; idx += 1) {
								// push object_id
								aObjData.push(data[iLang][idx].id);

								if (!sourceElement) {
									sourceElement = data[iLang][idx]._sourceElement;

									if ($.type(sourceElement) !== "string") {
										sourceElement = sourceElement.find("textarea").prop("id");
									}
								}

								// push lang_id
								iTransLang = iLang;
								try {
									iTransLang = parseInt(sourceElement.split('-')[1], 10); // multilangmultilang-3-1_1406302092651 -> 3
								} catch (e) {
									$.noop();
								}
								aLangData.push(iTransLang);
							}
						}
					}

					if (aObjData.length) {
						if (aObjData.length === 1) {
							iObjectID = parseInt(aObjData, 10);
							$.contensAPI(
								'object.getObjectRichtextClassFormats', {
									'objectId': iObjectID
								},
								function(result, success) {
									if (success) {
										iClassID = parseInt(result.class_id, 10);
										outputtypes = result.outputtypes.split(',');
									}
								}, [], {
									async: false
								});

							if (iClassID && outputtypes.length > 1) {
								bAddSnippet = false;

								CKEDITOR.instances[sourceElement].execCommand('selectOutputtype', {
									object_id: iObjectID,
									class_id: iClassID
								});
							}
						}

						if (bAddSnippet) {
							this._addObjectSnippet({
								obj_ids: aObjData.join(','),
								lang_ids: aLangData.join(','),
								source_elem: sourceElement,
								source_outputtypeid: outputtypes[0]
							});
						}
					}
				}
			} else {
				$.cms.cRowtype.prototype._transferValues.apply(this, arguments);
			}
		},

		_handleObjectPreview: function(event) {
			var rowEl = $(event.target).closest('.' + detailsClass),
				rowElData = rowEl.data(),
				oData = {
					objectid: rowElData.objectId
				},
				successfn;

			successfn = function(result, success, errnum) {
				if (parseInt(errnum, 10) === 0) {
					this._showObjectPreview(rowEl, result, rowElData.objectId);
				}
			};

			$.contensAPI(this.options.previewFn, oData, $.proxy(successfn, this));
		},

		_showObjectPreview: function(element, result, objectid) {
			var thumbTmpl = '<div style="overflow:hidden; height:inherit;"><div style="padding:10px;">{{html content}}</div></div>',
				tmplData = {},
				popUpWindowId = 'preview-object-' + objectid;

			tmplData.content = result;

			if ($('#' + popUpWindowId)) {
				$('#' + popUpWindowId).cWindow2('close', {});
			}

			$.cWindow2({
				id: popUpWindowId,
				title: this.options.i18n.custom.js_objectpreview,
				size: {
					x: 600,
					y: 550
				},
				minSize: {
					x: 200,
					y: 200
				},
				content: $.tmpl(thumbTmpl, tmplData),
				modal: true,
				zIndexAddOverride: parseInt($("table.cke_dialog").css("z-index"), 10) + 10
			});
		},

		_addObjectSnippet: function(oData) {
			var detailController = this.options.setup.controller + '?coevent=' + this.options.setup.controllerEvents.getObjectsDetails,
				requestData = {
					'object_idlist': oData.obj_ids,
					'outputtype_idlist': oData.source_outputtypeid,
					'lang_idlist': oData.lang_ids,
					'json_ucase': false,
					'columnlist': this.options.setup.columnlist,
					'strictsubobjectcheck': 0,
					'viewmode': 1
				};

			$.getControllerJSON(detailController, requestData, $.proxy(
				function(oResponse) {
					this._handleAddObjectSnippetSuccess(oResponse, oData.source_elem, oData.source_outputtypeid);
				}, this));
		},

		_changeObjectSnippet: function(oData, callback) {
			var detailController = this.options.setup.controller + '?coevent=' + this.options.setup.controllerEvents.getObjectsDetails,
				requestData = {
					'object_idlist': oData.obj_id,
					'outputtype_idlist': oData.outputtype_id,
					'lang_idlist': oData.lang_id,
					'json_ucase': false,
					'columnlist': this.options.setup.columnlist,
					'strictsubobjectcheck': 0,
					'viewmode': 1
				};

			$.getControllerJSON(detailController, requestData, $.proxy(
				function(oResponse) {
					this._handleAddObjectSnippetSuccess(oResponse, oData.source_elem);
					if (callback && typeof callback === 'function') {
						callback();
					}
				}, this));
		},

		_handleAddObjectSnippetSuccess: function(response, sourceElem, sourceOutputtypeId) {
			var key = (this.options.setup.columnlist).toLowerCase(),
				data = response.result[key],
				rowtypeId,
				rowRel,
				ckeditorId,
				class_id,
				objData,
				oKey,
				oSubObjectSelectData = [];

			if (parseInt(response.errorcode, 10) === 0) {
				try {
					for (oKey in data) {
						if (data.hasOwnProperty(oKey)) {
							class_id = data[oKey].objclasses;

							objData = {
								'data-outputtype_id': sourceOutputtypeId || data[oKey].meta.outputtype_id,
								'data-object_id': data[oKey].object_id,
								'data-lang_id': data[oKey].lang_id,
								'data-class_id': class_id,
								'contenteditable': 'false',
								'html': $.decodeHtml(data[oKey].objview)
							};

							oSubObjectSelectData.push(objData);

							if (!ckeditorId) {
								if (this.options.bCreateSubobjects || typeof sourceElem === 'string') {
									ckeditorId = sourceElem;
								} else {
									rowtypeId = $(this.element).attr('id');
									rowRel = $(sourceElem).attr('rel');
									ckeditorId = $(this.element).find('[id^="' + rowtypeId + "-" + objData["data-lang_id"] + "-" + rowRel + '"]').attr('id');
								}
							}
						}
					}

					if (window.CKEDITOR.instances[ckeditorId]) {
						window.CKEDITOR.instances[ckeditorId].fire('subobjectselect', oSubObjectSelectData);
						// update rowtype when inserting sub object
						this._handleInputUpdate(null, ckeditorId);
					}
				} catch (e) {
					// catch problems caused by missing data[oKey].meta - can happen if subobjects are in different languages and don't contain tgg/channel
					$.noop();
				}
			}
		},

		_checkLength: function(jEl, CKEid) {
			var parsedLengthMax = parseInt(this.options.validation.lengthmax, 10),
				parsedLengthMin = parseInt(this.options.validation.lengthmin, 10),
				lenMax = (parsedLengthMax || -1),
				lenMin = (parsedLengthMin || -1),
				minErrorMessage = this.options.i18n.validator.viollengthmin.replace('%min%', lenMin),
				maxErrorMessage = this.options.i18n.validator.viollengthmax.replace('%max%', lenMax),
				validationErrorsMessages = [],
				textRaw,
				charCountClean,
				wrapperElem;

			this.clearErrors();

			if (window.CKEDITOR.instances[CKEid]) {
				textRaw = window.CKEDITOR.instances[CKEid].getData();
			} else {
				textRaw = $(jEl).val();
			}

			if (textRaw !== undefined && $(jEl).length) {
				charCountClean = this._getLengthWithoutHTML(textRaw);
				wrapperElem = jEl.parents('.ui-form-row-multi');

				if (lenMin > 0) {
					if (charCountClean < lenMin) {
						validationErrorsMessages.push({
							jElWrp: wrapperElem,
							msg: {
								"minlength": minErrorMessage
							}
						});
					}
				}

				if (lenMax > 0) {
					if (charCountClean > lenMax) {
						validationErrorsMessages.push({
							jElWrp: wrapperElem,
							msg: {
								"maxlength": maxErrorMessage
							}
						});
					}
				}
				this.showErrors(validationErrorsMessages);
			}
		},

		// display current character usage
		_updateStatusBar: function(CKEid) {
			var editorInstance = window.CKEDITOR.instances[CKEid];

			if (editorInstance) {
				var textClearLength = 0,
					editorInfo = this.element.find("#editor-info_" + CKEid),
					parsedLengthMax = parseInt(this.options.validation.lengthmax, 10),
					lenMax = (parsedLengthMax || -1),
					renderText = this.options.i18n.custom.js_currentlength,
					regexRemaining = /%remaining%/gi,
					regexMaxLength = /%max%/gi,
					infoMessages = [],
					remainingClearCount = 0,
					htmlContent = "",
					infoCount,
					iMsg;

				textClearLength = this._getLengthWithoutHTML(editorInstance.getData(true));

				if (lenMax > 0) {
					remainingClearCount = lenMax - textClearLength;
					renderText = renderText.replace(regexRemaining, remainingClearCount).replace(regexMaxLength, lenMax);
					infoMessages.push(renderText);
					infoCount = infoMessages.length;

					for (iMsg = 0; iMsg < infoCount; iMsg += 1) {
						if (remainingClearCount >= 0) {
							htmlContent += '<li class="rowtype-subline">' + infoMessages[iMsg] + '</li>';
						} else {
							htmlContent += '<li class="rowtype-subline" style="color:rgb(214, 70, 68);">' + infoMessages[iMsg] + '</li>';
						}
					}
					editorInfo.html(htmlContent);
					editorInfo.show();
				}
			}
		},
		_getLengthWithoutHTML(str) {
			var tmp = document.createElement("div");
			tmp.innerHTML = str;
			if (tmp.textContent == "" && typeof tmp.innerText == "undefined") {
				str = "";
			} else {
				str = tmp.textContent || tmp.innerText;
			}
			str = str.replace(/(\r\n|\n|\r)/g, "");
			return str.length;

		},
		_setTranslationView: function _setTranslationView(status, ilangTrans) {
			$.cms.cRowtype.prototype._setTranslationView.apply(this, arguments);

			// only init on changeLanguage if admin form
			if (!this.oForm.options.monitorlangchange) {
				this._initRichLang(ilangTrans);
			}
		},
		changeLanguage: function changeLanguage(oldlanguage, newlanguage) {
			$.cms.cRowtype.prototype.changeLanguage.apply(this, arguments);

			// only init on changeLanguage if admin form
			if (!this.oForm.options.monitorlangchange) {
				this._initRichLang(newlanguage);
			}
		},
		_initRichLang: function _initRichLang(ilang) {
			var idx, CKEid, elLangWrp, jEl, jElWork;

			if (!this.initializedLangs.has(ilang)) {
				elLangWrp = this.oInputs.wrp[ilang];

				if (elLangWrp) {
					for (idx = 0; idx < elLangWrp.length; ++idx) {
						jEl = elLangWrp[idx];
						jElWork = jEl.is('input, textarea, select') ? jEl : jEl.find('input, textarea, select');
						CKEid = jElWork.attr('id');
						this.initializedLangs.put(ilang, CKEid);
						this.createEditor(CKEid, jElWork, idx, ilang);
					}
				}
			}
		},
		_toggleMultilangs: function _toggleMultilangs() {
			// only init on togglemultilangs if admin form
			if (!this.oForm.options.monitorlangchange) {
				for (var ilang in this.oInputs.langWrp) {
					this._initRichLang(parseInt(ilang, 10));
				}
			}

			$.cms.cRowtype.prototype._toggleMultilangs.apply(this, arguments);
		}
	});

	/* define templates */
	oRichtextTemplates = {
		'richtext-info': '<ul class="' + infoStyle + ' ' + infoClass + '" style="display:none;"></ul>',

		'richtext-link-treebase': '<div><div class="con-richtext-details-tree-wrapper">' +
			'	<div class="con-richtext-internal-tree-controls">' +
			'		{{tmpl(siteMenu) "richtext-site-menu"}}' +
			'		{{tmpl(langMenu) "richtext-lang-menu"}}' +
			'       {{if window.cms.cBaseApp.getEditorPageRights()}}<span class="con-icon con-icon-search sys-addtip" id="search_${ckeditor_id}" original-title="${window.cms.i18n.system.text.search}"></span>{{/if}}' +
			'	</div>' +
			'	<div id="pagetreewrp_${ckeditor_id}">' +
			'		<div id="pagetree_${ckeditor_id}"></div>' +
			'	</div>' +
			'</div></div>',

		'richtext-site-menu': '<div class="con-richtext-internal-tree-controls-site">' +
			'	<select {{if title}}title="${title}"{{/if}} id="sites_${$item.parent.data.ckeditor_id}"></select>' +
			'</div>',

		'richtext-lang-menu': '<div class="con-richtext-internal-tree-controls-lang">' +
			'	<select {{if title}}title="${title}"{{/if}} id="languages_${$item.parent.data.ckeditor_id}" name="viewmode"></select>' +
			'</div>',

		'richtext-objectFile-search': '<div>' +
			'	<div class="con-richtext-file-search">' +
			'		<div class="con-search">' +
			'			<input type="text" id="objFileSearchInp_${ckeditor_id}" name="search" class="selecttable-search" placeholder="${i18n.custom.js_searchfieldtext}">' +
			'			<button type="button" class="con-button selecttable-searchbtt">' +
			'				<div id="objFileSearchBtn_${ckeditor_id}" class="con-icon con-icon-search"></div>' +
			'			</button>' +
			'		</div>' +
			'	</div>' +
			'</div>',

		'richtext-objectFile-results': '<div>' +
			'	<div class="con-richtext-file-search-results js-objectFileLink-wrapper" id="extObjWrapper${ckeditor_id}">' +
			'		<div class="con-richtext-file-search-link"></div>' +
			'		<div id="extObjFileList${ckeditor_id}" style="display: none;" class="con-richtext-file-search-results-wrapper">' +
			'			<table class="con-list" cellpadding="0" cellspacing="0">' +
			'				<thead>' +
			'					<tr class="con-list-header">' +
			'						<th class="js-richtext-link-detail-object-id">${i18n.custom.js_header_file_id}</th>' +
			'						<th class="js-richtext-link-detail-classname">${i18n.custom.js_header_file_name}</th>' +
			'					</tr>' +
			'					<tr><th class="con-list-spacer" colspan="999"></th></tr>' +
			'				</thead>' +
			'				<tbody class="js-objectfile-resultList">' +
			'				</tbody>' +
			'			</table>' +
			'		</div>' +
			'		<div id="extObjFileNotFound${ckeditor_id}" style="display: none;">' +
			'	 		${i18n.custom.js_no_files_found}' +
			'		</div>' +
			'	</div>' +
			'</div>'
	};

	/* compile templates */
	for (tmpRichtextTemplate in oRichtextTemplates) {
		if (oRichtextTemplates.hasOwnProperty(tmpRichtextTemplate)) {
			$.template(tmpRichtextTemplate, oRichtextTemplates[tmpRichtextTemplate]);
		}
	}

	$.extend($.cms.cRowtype_richtext_default, {
		version: "1.0"
	});

}(jQuery, window, document, _, HashMap));
