/*
 * CONTENS cRowtype.cRowtype_list_rel_n
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {

	$.widget("cms.cRowtype_list_rel_n", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				onchangejsfunction: null,
				dttabdeleterow: true,
				dttabnumbering: false,
				dttabreordering: false,
				dttabsortisedit: false,
				dttabsortrow: false,
				isuseclipboard: false,
				maxvisibleentries: null,
				maxheight: 200,
				optiontable: null,
				preselected: [],
				columnlist: '',
				filter: null
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			generatedToolbar: {}
		},

		widgetEventPrefix: 'cms-rowtype-list_rel_n-',
		widgetBaseClass: 'ui-cms-rowtype-list_rel_n',

		/* standard widget functions */
		_create: function() {
			this._plugin($.cms.extensions.selecttable);

			if (this.options.setup.optiontable) {
				this.options.setup.optiontable = this.options.setup.optiontable.replace('co_', '');

				// setting options for selectTable
				this.options.selectTable.settings.toolbarElements.push({
					type: 'search',
					position: 'left'
				});
				this.options.selectTable.defaults.search.optiontable = this.options.setup.optiontable;
				this.options.selectTable.defaults.search.columnlist = this.options.setup.columnlist;
				this.options.selectTable.defaults.search.filter = this.options.setup.filter;
			}

			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			this.element.on('htmlMarkupAdded.rowtype', $.proxy(this._initSelectable, this));
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			this._selectTable_init(this.options.setup.aOptions, this.options.setup.preselected, this.options.setup.optiontable);
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function(key, value) {
			if (key === "removerows") {
				this.oInputs['langWrp'][this.language].find('.selecttable-table-selected .con-icon-close').click();
				this._selectTable_search_success(value);
			}

			if (key === "options") {
				this.oInputs['langWrp'][this.language].find('.selecttable-table-selected > div').empty();
				this._selectTable_search_success(value);
			}
			if (key === "preselected") {
				this._addPreselected(value);
			}

			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		/* custom functions */

		/* internal custom functions */
		_handleInputChange: function _handleInputChange(e) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(this, e.target);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_initSelectable: function _initSelectable(event, iLang) {
			this._selectTable_initLang(iLang, this.options.setup.preselected);
		},
		_initElement: function(jEl, idx, iLang) {
			this.options.generatedToolbar[iLang] = this.element.find('.ui-form-row-list_rel_n[rel="' + iLang + '"] .con-toolbar');
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_extendServerValue: function _extendServerValue(value) {

			return value;
		},
		_setValue: function(jEl, value, dataKey, opt) {
			var data,
				el,
				vals,
				labels,
				x;

			if (jEl) {
				if (dataKey === "islocked" && value.value) {
					el = this._getInput(opt.idx, opt.ilang, 'inp');
					el.closest('.con-selecttable-row').find('.sys-selecttable-moveBottom').remove();
				} else {
					if (isNaN(value.value) && value.value.indexOf(',') !== -1) {
						vals = value.value.split(',');
						labels = value.label.split(',');
						x = 0;
						jEl.val(vals[0]);
						jEl.prev().html(labels[0]);
						for (x = 1; x < vals.length; ++x) {
							data = {
								label: labels[x],
								value: vals[x]
							};
							this.addRow(0, data, true);
						}
					}
				}
			}
		},
		_getValue: function(jEl, asServerFormat) {
			var lwrp = jEl.closest('.ui-form-row-multi'),
				value = null;

			if (lwrp.data('rowtype-element')) {
				value = jEl.val();
			}
			return (asServerFormat ? this._convertToServerValue(value) : value);
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},

		_getValueObject: function _getValueObject(jEl, asServerFormat) {
			return {
				value: this._getValue(jEl, asServerFormat),
				label: jEl.closest('.ui-form-row-multi').find('.con-selecttable-row-label').text()
			};
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_canCopySrcElement: function _canCopySrcElement(srcElement) {
			return this.options.multiusage && document.body.contains(srcElement[0]);
		}
	});

	$.extend($.cms.cRowtype_list_rel_n, {
		version: "1.0"
	});

}(jQuery));
