/*
 * CONTENS cNavigation
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Extends:
 *	 jquery.cms.tree.js
 */
require("./jquery.cms.tree");

(function($) {

	$.widget("cms.cNavigation", $.cms.cTree, {
		/*
		 * widget settings and default options, only define those specific to cNavigation
		 */
		options: {
			page_id: 0,
			site_id: 0,
			lang_id: 0,
			excludeid: '',
			theme: 'contens',
			navigation: true,
			showzoom: true,
			allowdragdrop: false,
			allowscroll: false,
			autoSelect: true,
			isDisableOffline: true,
			languageSelectorId: null,
			siteSelectorId: null,
			siteslanguagesdatafn: "tree.getNavSitesLanguages",
			siteslanguagesargs: {
				site_id: 0,
				lang_id: 0,
				browtype: false
			},
			siteslanguagesdata: null
		},
		widgetEventScope: '.cNavigation',
		widgetBaseClass: 'ui-cms-navigation',

		_create: function _create() {
			/* add the flyout for the tree */
			this.flyout = $('<div id="' + this.options.id + '-flyout" class="con-flyout js-flyout"></div>');
			this.flyout.appendTo(this.element);
			this.moveQueue = [];

			if (this.options.languageSelectorId === null && this.options.siteSelectorId === null) {
				this.siteIdTemplate = $('<div style="float:left;padding-right:10px"><select id="site_ID_' + this.options.id + '" name="site_ID" style="width:130px;"></select></div>');
				this.langIdTemplate = $('<div style="float:left;"><select id="lang_ID_' + this.options.id + '" name="lang_ID" style="width:130px;"></select></div>');
				this.template = $('<div class="ui-widget con-form" style="margin-bottom:20px"><form id="form_pagetree"><div class="ui-form-row-first ui-form-select" ><div style="float:left;padding-right:10px"><select id="site_ID_' + this.options.id + '" name="site_ID" style="width:130px;"></select></div><div style="float:left;"><select id="lang_ID_' + this.options.id + '" name="lang_ID" style="width:130px;"></select></div></div></form></div>');
				this.template.appendTo(this.element);
				this.siteidElem = $('#site_ID_' + this.options.id);
				this.langidElem = $('#lang_ID_' + this.options.id);
			} else {
				if (typeof this.options.siteSelectorId === "string") {
					this.siteidElem = $('#' + this.options.siteSelectorId);
					this.langidElem = $('#' + this.options.languageSelectorId);
				} else {
					this.siteidElem = this.options.siteSelectorId;
					this.langidElem = this.options.languageSelectorId;
				}
			}

			// set the global variables
			this.navTree = this.element;
			this._superApply([]); // call create in parent widget
		},
		_init: function _init() {
			// ensure that the options are numeric
			this._setOption("page_id", parseInt(this.options.page_id, 10));
			this._setOption("site_id", parseInt(this.options.site_id, 10));
			this._setOption("lang_id", parseInt(this.options.lang_id, 10));

			// drag & drop
			if (this.options.allowdragdrop && !window.cms.cBaseApp.isTouchDevice()) {
				this._setOption('dragndrop',
					function() {
						// since the root node is not allowed to be dragged anyway all other drag options are allowed
						return true;
					});
			}

			if (typeof this.options.datafn === 'function') {
				this._setOption("datafn", this.options.datafn);
			} else {
				this._setOption('datafn', function(n) {
					var oMeta = n.data,
						iFolderID = oMeta ? oMeta.folder_id : 0,
						iPageID = oMeta ? oMeta.page_id : 0;
					return {
						"operation": iFolderID ? "getchildren" : "getnewtree",
						"pageID": iPageID,
						"siteID": this.options.site_id,
						"langID": this.options.lang_id,
						"excludeid": this.options.excludeid,
						"disableoffline": this.options.isDisableOffline,
						"_lowercase": true,
						"initiallySelected": this.options.page_id
					};
				});
			}
			this.options.search = {
				isIdSearch: true,
				"ajax": {
					"url": function() {
						return window.cms.cBaseApp.getRpcEndpoint();
					},
					"data": function(pid) {
						return {
							"operation": "getparents",
							"pageid": pid.replace('node_', ''),
							"siteid": this.options.site_id,
							"langid": this.options.lang_id,
							"returnwithprefix": 'node_',
							"_action": 'tree.get',
							"_lowercase": true,
							"_returnformat": 'json_native'
						};
					}
				}
			};

			this._superApply([]); // call init in parent widget
			this._initializeSitesLanguages(); // initialize the dropdown
		},
		destroy: function destroy() {
			// only remove the langid and siteid elements if they were added by the widget
			if (this.options.languageSelectorId === null) {
				this.langidElem.remove();
			}
			if (this.options.siteSelectorId === null) {
				this.siteidElem.remove();
			}
			this.element.off(this.widgetEventScope);
			this._superApply([]);
		},
		/* Event Handling Functions */
		_handleMoveNode: function _handleMoveNode(event, data) {
			event.stopPropagation();
			this._moveNode(data);
		},
		_handleLangIdChange: function(event, target) {
			event.stopPropagation();
			this.switchLangId(parseInt($(event.currentTarget).val(), 10), target);
		},
		_handleSiteIdChange: function _handleSiteIdChange(event) {
			/* *_handleSiteIdChange(){
			 * populates the sites languages dropdowns
			 * */
			var c_siteID = parseInt($(event.currentTarget).val(), 10);

			event.stopPropagation();

			if (event.currentTarget.selectedIndex === -1) {
				event.currentTarget.selectedIndex = 0;
				this.siteidElem.trigger('reBuild.dropdown');
				c_siteID = parseInt(event.currentTarget.value, 10);
			}
			this.switchSiteId(c_siteID);
		},
		_handleSearchComplete: function _handleSearchComplete(event, data) {
			/**
			 * called after a search operation is complete
			 **/
			var scrollAllow = window.cms.oSettings.javascript.pageTreeAllowScroll,
				selected = this.getSelected();

			if (data.res[0]) {
				if (selected.length && selected.indexOf('node_' + this.options.page_id) === -1) {
					this.clearSearch();
					this.deselectAll();
					data.instance.select_node(data.res[0]); // ensure that the node is selected
				}
			}
			if (data.res.length && scrollAllow !== "none") {
				this.getNode(data.res[0], true).scrollintoview({
					direction: scrollAllow
				});
			}
			this._superApply([event, data]);
		},
		/* custom function */
		switchSiteId: function switchSiteId(newSiteId) {
			if (isNaN(newSiteId)) {
				return; // prevent sending NaNs to the server
			}

			/**
			 * rebuilds the langauge dropdown when the user changes sites
			 * gets a new tree for the specified site/language
			 **/
			var c_siteID = parseInt(newSiteId, 10),
				eventname = 'switchSite',
				i,
				bLoadPage = false,
				ilang = parseInt(this.langidElem.val(), 10),
				currlang = parseInt(this.langidElem.val(), 10),
				oSitesLang = window.cms.cBaseApp.getSitesLanguages();

			/* don't bother doing anything if they "switched" the site to the current site */
			if (c_siteID !== this.options.site_id) {
				this.langidElem.find('option').remove();
				for (i = 0; i < oSitesLang.length; ++i) {
					if (c_siteID === parseInt(oSitesLang[i].site_id, 10)) {

						if (!currlang || parseInt(oSitesLang[i].lang_id, 10) === currlang) {
							this.langidElem.append('<option value="' + oSitesLang[i].lang_id + '" selected data-dir="' + oSitesLang[i].dir + '">' + oSitesLang[i].lang_idtxt + '</option>');
						} else {
							this.langidElem.append('<option value="' + oSitesLang[i].lang_id + '" data-dir="' + oSitesLang[i].dir + '">' + oSitesLang[i].lang_idtxt + '</option>');
						}
					}
				}

				this.langidElem.trigger('reBuild.dropdown');
				this.element.off('loadedTree.cNavigation');

				this._setOption("lang_id", parseInt(this.langidElem.val(), 10));
				this.options.site_id = c_siteID;
				this.options.page_id = -1;

				if (ilang && this.options.lang_id !== ilang) {
					bLoadPage = true;
				}

				if (bLoadPage) {
					this.element.one({
						"loadedTree.cNavigation": $.proxy(function() {
							this._setOption("textDirection", this._textDirection(this.langidElem.find("option:selected").data('dir'), 10));
							this.element.trigger(eventname + '.cNavigation', [this.options.page_id, this.options.lang_id, this.options.site_id]);
						}, this)
					});
					this._reinitialize();

				} else {
					this.element.one({
						"loadedTree.cNavigation": $.proxy(function() {
							_.defer($.proxy(function() {
								this.element.trigger(eventname + '.cNavigation', [this.options.page_id, this.options.lang_id, this.options.site_id]);
							}, this));
						}, this)
					});
					this._reinitialize();
				}
			}
		},
		switchLangId: function switchLangId(lang_id, target) {
			if (isNaN(lang_id)) {
				return;
			}

			/**
			 *
			 * attempts to load a tree for the selected site/language,
			 * once the tree is loaded we try to synchronize the tree the page in the other
			 * language, if it exists, if it doesnt exist the root node is selected.
			 *
			 * if the site doesnt exist in the other language open the pagenewlang wizard
			 **/
			var iFolderID = 0,
				eventname = 'switchLang',
				elSelect,
				callBack;

			if (target && target.args && target.args.orgevent) {
				elSelect = $(target.args.orgevent.currentTarget);

				if (elSelect.hasClass('con-lang-missing')) {
					$(document.body).trigger('loadaction', ['pagenewlang', {
						page_id: window.cms.cBaseApp.getPageID(),
						lang_id: target.args.value
					}]);
					this.langidElem.cDropdown('setSelectedIndexByValue', this.options.lang_id, true);
					return;
				}
			}
			if (lang_id !== parseInt(this.options.lang_id, 10)) {

				/* dont bother switching languages if they selected the current language  */

				if (this.getSelected().length) {
					iFolderID = this.getSelectedNode().data.folder_id;
				}
				this.options.site_id = parseInt(this.siteidElem.val(), 10);
				this._setOption("lang_id", lang_id);
				this.langidElem.trigger('click.languageid.cNavigation', lang_id);

				callBack = function(result) {
					var pageid = -1;
					if (!isNaN(result) && result !== "") {
						pageid = parseInt(result, 10);
					}
					this.options.page_id = pageid;
					this.element.one({
						"loadedTree.cNavigation": $.proxy(function() {
							this._setOption("textDirection", this._textDirection(this.langidElem.find("option:selected").data('dir'), 10));
							this.element.trigger(eventname + '.cNavigation', [pageid, this.options.lang_id, this.options.site_id]);
						}, this)
					});
					this._reinitialize();
				};

				$.contensAPI('page.getpageid', {
					'folderid': iFolderID,
					'langid': this.options.lang_id,
					'siteid': this.options.site_id
				}, $.proxy(callBack, this));
			}
		},
		search: function search(pageid) {
			/**
			 * performs a search operation
			 * @pageid - numeric - the pageid to search for
			 **/
			pageid = 'node_' + pageid;
			return this._superApply([pageid]);
		},
		refreshNode: function refreshNode(node, bWithParent) {
			/**
			 * reloads the current node, and all siblings - if current node has no parent then the root tree is reloaded
			 * @node {mixed} the node to be reloaded, can be either a page_id (290) or the node_id (node_290)
			 * @bWithParent {Boolean} determines that either the parent of the current node is also reloaded or only the children of the node
			 **/

			// check to see if the node exists e.g. has been loaded
			if (this.getNode(node)) {
				// ensure that the value being passed to refresh node is correct
				node = 'node_' + (typeof node === 'string' ? node.replace('node_', '') : node);
				bWithParent = bWithParent === undefined ? true : bWithParent;
				this._superApply([node, bWithParent]);
			} else {
				this.refresh(node);
			}
		},
		refresh: function refresh(node) {
			/**
			 * rebuilds the tree
			 * @node {numeric} - the page to be selected after reloading the tree
			 **/
			this.options.page_id = node;
			this._reinitialize(false);
		},
		reload: function reload(node) {
			this.options.page_id = node; // set the page_id option before we prepend 'node_'
			node = 'node_' + node;
			this._superApply([node]);
		},
		remove: function remove(node) {
			/**
			 * remove a node from the tree
			 **/
			// ensure that the value being passed to refresh node is correct
			node = 'node_' + (typeof node === 'string' ? node.replace('node_', '') : node);
			this._superApply([node]);
		},
		removeByNode: function remove(node) {
			return this.tree.jstree('delete_node', node);
		},
		setSiteLang: function setSiteLang(siteid, langid, pageid) {
			/**
			 * reinitializes the navigation tree with a different site and language id.
			 * @siteid - numeric
			 * @langid - numeric
			 * @pageid - numeric - the initially selected page id
			 **/
			this.options.site_id = parseInt(siteid, 10);
			this.options.page_id = parseInt(pageid, 10);
			this._setOption("lang_id", parseInt(langid, 10));

			this._initializeSitesLanguages();
			this._reinitialize();
		},
		setSite: function setSite(siteid) {
			this.siteidElem.cDropdown('changeSelected', siteid, false);
			this.siteidElem.trigger('change');
		},
		getSite: function getSite() {
			return this.siteidElem.val();
		},
		getNode: function getNode(node, asDom) {
			/**
			 * returns the data associated with a node
			 * @node {mixed} either the node_id or a dom element
			 * @asDom {boolean} true returns jquery element object
			 **/
			// ensure the node has the correct format if its a string
			if (typeof node === 'string') {
				node = 'node_' + node.replace('node_', '');
			} else if (typeof node === 'number') {
				node = 'node_' + node;
			}
			return this._superApply([node, asDom]);
		},
		getParent: function getParent(node) {
			/**
			 * returns the data associated with parent node of the supplied node
			 * @node {mixed} either the node_id or a dom element
			 **/
			// ensure that the value being passed to refresh node is correct
			if (typeof node === 'string') {
				node = 'node_' + node.replace('node_', '');
			} else if (typeof node === 'number') {
				node = 'node_' + node;
			}
			return this._superApply([node]);
		},
		setLanguage: function setLanguage(langid) {
			this.langidElem.cDropdown('changeSelected', langid, false);
		},
		getLanguage: function getLanguage() {
			return this.langidElem.val();
		},
		/* internal custom functions */

		_textDirection: function _textDirection(iDir) {
			var retVal = 'rtl';
			if (iDir === 0) {
				retVal = 'rtl';
			} else {
				retVal = 'ltr';
			}
			this.element.trigger('textdirectionchanged.cNavigation', retVal);
			return retVal;
		},
		_reinitialize: function _reinitialize(wipeData) {
			/**
			 * rebuilds the tree
			 * @wipeData {Boolean} - optionally wipes any initial data that was used during loading
			 **/
			wipeData = wipeData === undefined ? true : wipeData;

			if (wipeData && !$.isEmptyObject(this.options.json_api)) {
				this.options.json_api = {};
			}

			this._superApply([]);
		},
		_setStateInitOptions: function _setStateInitOptions() {
			/**
			 * overrides method in cTree
			 **/
			$.extend(this.init_options, {
				state: {
					key: this.options.id + '_' + this.options.site_id + '_' + this.options.lang_id
				}
			});
		},
		_getTreeId: function getTreeId() {
			return this.tree_id;
		},
		_setTreeId: function setTreeId() {
			this.tree_id = this.options.id + '_' + this.options.site_id + '_' + this.options.lang_id;
		},
		_setSearchInitOptions: function _setSearchInitOptions(init_options) {
			/**
			 * sets the data key to store the state of the tree, by default use the id
			 * @init_options an object containing initialization information for the tree
			 * can be overriden by widgets extending this
			 **/
			if (!this.options.search) {
				this._superApply([init_options]);
			} else {
				$.extend(init_options, {
					"search": {
						"ajax": $.proxy(this._doParentSearch, this),
						"search_callback": $.proxy(this._doNodeSearch, this)
					}
				});
			}
			$.extend(this.baseEvents, {
				"search.jstree": $.proxy(this._handleSearchComplete, this)
			});
		},
		_initializeSitesLanguages: function() {
			var data = {
					'_lowercase': true,
					'_returnformat': 'json_native'
				},
				callBack;

			$.extend(data, this.options.siteslanguagesargs);

			callBack = function(result) {
				window.cms.cBaseApp.setSitesLanguages(result);
				this._buildSitesLanguages(result);
			};

			if (!this.options.siteslanguagesdata) {
				$.contensAPI(this.options.siteslanguagesdatafn, data, $.proxy(callBack, this), null);
			} else {
				this._buildSitesLanguages(this.options.siteslanguagesdata);
			}
		},
		_buildSitesLanguages: function _buildSitesLanguages(oSitesLang) {
			var c_siteID = this.options.site_id,
				iSite = 0,
				i;
			// prevent multi-binding when reinitializing tree
			this.langidElem.off('change.cNavigation');
			this.siteidElem.off('change.cNavigation');
			// empty any existing options
			this.langidElem.find('option').remove();
			this.siteidElem.find('option').remove();

			for (i = 0; i < oSitesLang.length; ++i) {
				if (c_siteID == parseInt(oSitesLang[i].site_id, 10)) {
					if (parseInt(oSitesLang[i].lang_id, 10) == this.options.lang_id) {
						this.langidElem.append('<option value="' + oSitesLang[i].lang_id + '" selected data-dir=' + oSitesLang[i].dir + '>' + oSitesLang[i].lang_idtxt + '</option>');
					} else {
						this.langidElem.append('<option value="' + oSitesLang[i].lang_id + '"data-dir=' + oSitesLang[i].dir + '>' + oSitesLang[i].lang_idtxt + '</option>');
					}
				}
				if (iSite != parseInt(oSitesLang[i].site_id, 10)) {
					if (oSitesLang[i].site_id == this.options.site_id) {
						this.siteidElem.append('<option value="' + oSitesLang[i].site_id + '" selected data-dir=' + oSitesLang[i].dir + '>' + oSitesLang[i].site_idtxt + '</option>');
					} else {
						this.siteidElem.append('<option value="' + oSitesLang[i].site_id + '" data-dir=' + oSitesLang[i].dir + '>' + oSitesLang[i].site_idtxt + '</option>');
					}
				}
				iSite = parseInt(oSitesLang[i].site_id, 10);
			}
			this.siteidElem.trigger('reBuild.dropdown');
			// bind the events
			this.langidElem.one('rebuilt.dropdown', $.proxy(function() {
				this.langidElem.trigger('initializedsiteslangs', this.options.navigation);
			}, this));
			this.langidElem.trigger('reBuild.dropdown');

			this.langidElem.on({
				'change.cNavigation': $.proxy(this._handleLangIdChange, this)
			});
			this.siteidElem.on({
				'change.cNavigation': $.proxy(this._handleSiteIdChange, this)
			});

			this.element.on({
				'refreshSitesLangs.cNavigation': $.proxy(this._refreshSitesLanguages, this)
			});

			this._setOption('textDirection', this._textDirection(parseInt(this.langidElem.find("option:selected").data('dir'), 10)));

		},
		_refreshSitesLanguages: function _refreshSitesLanguages() {
			this._initializeSitesLanguages();
		},
		_handleRefreshNodeComplete: function _handleRefreshNodeComplete() {
			/**
			 * called every time after a node is refreshed
			 * @data - the data of the loaded node
			 **/
			this._superApply(arguments);
			this.element.trigger('RefreshNodeComplete.cNavigation', arguments);
		},
		_handleReady: function _handleReady(event) {
			/**
			 * overrides method in cTree
			 * if no node is selected we need to automatically select a node
			 **/
			var sel = this.getSelected(),
				state = this.getState(),
				scrollAllow = window.cms.oSettings.javascript.pageTreeAllowScroll,
				treeroot,
				strZoomWarning;

			this.options.status = 'idle'; // defined in cms.tree.js options
			event.stopPropagation();

			if (sel.length === 0 && state.core.selected.length === 0) {
				// nothing selected and nothing should be selected
				if (this.options.page_id === -1 && this.options.autoSelect) {
					this.options.page_id = this.getParent().data.page_id;
					this.search(this.options.page_id);
				} else if (this.options.page_id > 0) {
					this.search(this.options.page_id);
				}
			} else {
				// there is a page selected make sure its the right one
				if (sel[0] === state.core.selected[0]) {
					// page_id -1 means that the user manually switched sites, set the page_id from the state
					if (this.options.page_id === -1) {
						this.options.page_id = parseInt(state.core.selected[0].replace('node_', ''), 10);
						this.search(this.options.page_id);
					} else if (this.options.page_id !== parseInt(state.core.selected[0].replace('node_', ''), 10)) {
						// if the selected page the page form the state, and page_id is > -1 then we arrived here via a "deep-link"
						this.search(this.options.page_id);
					}
				}
			}

			if (this._checkSite() === false) {
				this.setSite(this.options.site_id);
			} else if (this._checkLang() === false) {
				this.setLanguage(this.options.lang_id);
			}

			// zoom
			if (this.options.showzoom) {
				treeroot = this.getParent();
				if (!treeroot) {
					return false;
				}
				if (treeroot.data.zoomed === true) {
					if ($('.js-zoom-warning', this.element).length === 0) {
						strZoomWarning = '<div class="con-toolbar top small con-page-tree-restricted js-zoom-warning">' +
							'<div class="con-toolbar-left"><div>' + window.cms.i18n.system.text.pagetreeiszoomed + '</div></div>';
						strZoomWarning += '<button type="button" class="con-button con-button-no-ds sys-addtip js-pagetree-zoom-out" original-title="' + window.cms.i18n.system.text.pagetreezoomout + '">' +
							'<div class="con-icon con-icon-pagetree-zoom-out"></div>' +
							'</button>';
						strZoomWarning += '</div>';
						$(strZoomWarning).prependTo(this.element);
					}
				} else {
					$('.js-zoom-warning', this.element).remove();
				}
			}

			// scroll
			if (this.options.allowscroll && sel.length) {
				if (scrollAllow !== "none") {
					this.getNode(sel[0], true).scrollintoview({
						direction: scrollAllow
					});
				}
			}

			this.element.trigger('loadedTree.cNavigation');
		},
		_checkSite: function _checkSite() {
			/**
				compares the selected site to the option site
				returns true if matches false otherwise
			**/
			if (parseInt(this.siteidElem.val(), 10) !== parseInt(this.options.site_id, 10)) {
				return false;
			}
			return true;
		},
		_checkLang: function _checkLang() {
			/**
				compares the selected language to the option language
				returns true if matches false otherwise
			**/
			if (parseInt(this.langidElem.val(), 10) !== this.options.lang_id) {
				return false;
			}
			return true;
		},
		_doParentSearch: function _doParentSearch(node, callback) {
			/**
			 * get the parents to open for the specified node
			 * @node the node id which we are looking for
			 * @callback the callback to call with an array of nodes to open
			 **/

			var pageID = parseInt(node.replace('node_', ''), 10);
			if (pageID === 0) {
				callback.call([]);
				return;
			}
			if (!$.isEmptyObject(this.getNode(node))) {
				callback.call(this.getNode(node).parents);
			} else {
				// make an ajax call to get the parents
				$.contensAPI('tree.get', {
						"operation": "getparents",
						"pageid": pageID,
						"siteid": this.options.site_id,
						"langid": this.options.lang_id,
						"returnwithprefix": 'node_'
					},
					$.proxy(callback, this));
			}
		},
		_doNodeSearch: function doNodeSearch(str, node) {
			/**
				* searches each node return true when a match is achieved -
					this will be called for each element in the trees data structure so it needs to be fast!
				* @str the search string - node_id
				* @the jstree data of a node
			**/
			if (str === node.id) {
				this.selectNode(str, true);
				return true;
			}
			return false;
		},
		_moveNode: function _moveNode(data) {
			/**
			 * calls the serverside function to actually move the page to its droped location in the tree
			 **/
			var sAction = 'page.move',
				oData = {},
				IsSibling = false,
				targetPageId,
				fctSuccess = $.proxy(function(result, success, errorcode, errormessage) {
					var page_id = oData.sourcePageId,
						selNode;

					if (!success && errorcode == 1501) { // confirmLostChanges AND page has subpages: ask for permission to move
						var oQuestion = $('<div id="dialog-confirm" title="' + window.cms.i18n.system.text.pagemove + '">' + errormessage + '</div>'),
							buttons = {
								'ok': {
									'text': window.cms.i18n.system.text.ok,
									'data-buttontype': 'ok',
									'click': function() {
										oQuestion.cDialog("close");
										// re-call api to move WITHOUT asking for permission again
										oData.isCheckSubpages = false;
										$.contensAPI(sAction, oData, $.proxy(fctSuccess, this), [100, 101, 102, 1003, 1404, 1501]);
									}
								},
								'cancel': {
									'text': window.cms.i18n.system.text.cancel,
									'data-buttontype': 'cancel',
									'click': function() {
										oQuestion.cDialog("close");
										// re-call function for cleanup
										fctSuccess.call(this, result, success, 1502, errormessage);
									}
								}
							};

						oQuestion.cDialog({
							'modal': true,
							'resizable': false,
							'stack': true,
							'buttons': buttons
						});
						return;
					}

					this.moveQueue.pop();
					if (!success) {
						this.element.trigger('showloadinglayer.workspace', false);
						if (errorcode != 1502) {
							this.element.trigger('showmessage.workspace', [errormessage, "error"]);
						}
						selNode = this.getSelectedNode();
						if (selNode.length) {
							page_id = selNode.data.page_id;
						}
						this.refresh(page_id);
						return;
					}

					if (this.moveQueue.length === 0) {
						this.deselectAll();
						this.element.trigger('dropcomplete.cNavigation', [{
							page_id: page_id,
							targetpage_id: oData.targetPageId,
							issibling: oData.IsSibling
						}]);
					}
				}, this);

			if (data.position > 0 && data.parent === data.old_parent) {
				/**
				 * we are moving the node within the branch.
				 * the targetpage is the page "above" where we are inserting this page
				 **/
				targetPageId = this.getPreviousNode(data.node).data.page_id;
				IsSibling = true;

			} else if (data.position > 0) {
				/**
					we are inserting the node under another parant into a given position
				**/
				targetPageId = this.getNode(this.getNode(data.parent).children[data.position - 1]).data.page_id;
				IsSibling = true;

			} else {
				/**
					we are inserting the node either in an empty branch or at the top
				**/
				targetPageId = this.getNode(data.parent).data.page_id;
				IsSibling = false;
			}

			oData = {
				targetPageId: targetPageId,
				sourcePageId: data.node.data.page_id,
				IsSibling: IsSibling,
				isCheckSubpages: window.cms.oSettings.javascript.confirmLostChanges
			};

			this.moveQueue.push(oData.sourcePageId);
			this.element.trigger('showloadinglayer.workspace', true);
			$.contensAPI(sAction, oData, $.proxy(fctSuccess, this), [100, 101, 102, 1003, 1404, 1501]);
		}
	});

	$.extend($.cms.cNavigation, {
		version: "2.0"
	});

}(jQuery));
